<template>
  <v-card style="background-color: hsla(0, 0%, 0%, 0.1);" class="mx-2" elevation="6">
    <v-card-title style="border-bottom: 4px solid white; background-color: hsla(0, 0%, 10%, 0.1);">
      <v-layout row justify-space-between nowrap>
        <h3 class="list-title">{{ name }}</h3>
        <span v-if="helpText" class="helper-text"><strong>{{ helpText }}</strong></span>
      </v-layout>
    </v-card-title>
    <v-card-text class="list">
      <v-layout justify-start align-center column nowrap fill-height>
        <draggable class="draggable" :list="orders" handle=".the-drag" @change="move" group="orders">
<!--          <component :is="card" :order="order" v-for="order in orders" :key="order.id"/>-->
          <div v-for="order in orders" :key="order.id">
            <slot name="card" v-bind:order="order"/>
          </div>
        </draggable>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import card from './card.vue'
import draggable from 'vuedraggable'
import shippingCard from '../cards/shippingCard'
export default {
  name: 'list',
  components: {
    // card,
    draggable
    // 'shipping-card': shippingCard
  },
  props: {
    name: {
      required: true,
      type: String
    },
    helpText: {
      required: false,
      type: String,
      default: ''
    },
    orders: {
      required: true,
      type: Array
    },
    status: {
      required: true,
      type: String
    }
  },
  computed: {
    isShipping () {
      return this.$route.fullPath.includes('shipping')
    },

    hasSTwithError () {
      for (const order of this.orders) {
        if (order.has_error) {
          return true
        }
      }
      return false
    }
  },
  data () {
    return {
      card: ''
    }
  },
  methods: {
    countClass (count) {
      return `tool-tip-font-${String(count).length}`
    },

    move (event) {
      if (event.added) {
        this.$emit('dragged', event.added.element, this.status)
      }
    }
  },
  mounted () {
    this.card = this.isShipping ? 'shipping-card' : 'card'
  }
}
</script>
