<template>
  <v-card
    hover
    height="113"
    width="350"
    class="card the-drag"
    :style="cardStyle"
  >
    <v-layout row nowrap justify-space-between align-center fill-height>
      <!-- Order Details -->
      <v-layout class="card-info" column align-start justify-start nowrap fill-height pr-0 mr-0>
        <v-card v-ripple elevation="1" height="30px" class="pa-1 transaction-card" @click="goToOrder"><h4>{{ order.label }}</h4></v-card>
        <span>Rep: {{ order.rep }}</span>
        <v-spacer></v-spacer>
        <v-fade-transition>
          <v-progress-circular
            v-if="loadingItemCount"
            indeterminate
            color="primary"
            size="20"
            width="2"
            style="margin-bottom: 6px"
          />
          <span
            v-else
            class="bigger"
          >
          <strong>{{ itemCount }} Item{{ itemCount > 1 ? 's' : '' }}</strong>
        </span>
        </v-fade-transition>
      </v-layout>
      <!-- Shipping Stuff -->
      <v-layout class="card-ship" align-center justify-start px-0 mx-0>
        <v-container
          pa-0 ma-0
        >
          <v-layout
            column nowrap align-center
          >
            <v-avatar
              size="30px"
              :class="shipperClass"
              v-if="order.service"
            >
            </v-avatar>
            <span
              v-if="order.service"
            >{{ order.service }}
            </span>
          </v-layout>
        </v-container>
      </v-layout>
      <!-- Assignee -->
      <v-layout class="card-assignee" column justify-start align-end fill-height pt-2 pl-0 ml-0>
        <v-avatar
          class="assignee"
          :class="profileColorTextColor"
          :color="needsAssignee ? '#cfcfcf' : order.assigneeColor"
          @click="needsAssignee ? claim() : ''"
          v-if="order.assignee !== undefined"
        >
          <span>{{ order.assignee }}</span>
        </v-avatar>
      </v-layout>
    </v-layout>
    <print-speed-dial
      style="margin-bottom: -30px; margin-right: -10px;"
      @list="$emit('list', { value: order })"
    />
  </v-card>
</template>

<script>
import PrintSpeedDial from '@/components/buttons/PrintSpeedDial'
import { GET_ITEM_COUNT_FOR_PURCHASE_RMA, GET_ITEM_COUNT_FOR_RMA } from '@/api/graphql/Constants/RMAs'
import { GET_ITEM_COUNT_FOR_SALE } from '@/api/graphql/Constants/Orders'
import { ViewPurchaseRma, ViewSaleRma, ViewSaleTransaction } from '@/lib/routerHelper'

export default {
  name: 'card',
  components: {
    'print-speed-dial': PrintSpeedDial
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    profileColorTextColor () {
      if (this.order.assigneeColor) {
        const c = this.order.assigneeColor.substring(1) // strip #
        const rgb = parseInt(c, 16) // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff // extract red
        const g = (rgb >> 8) & 0xff // extract green
        const b = (rgb >> 0) & 0xff // extract blue

        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

        if (luma < 128) {
          return 'white--text'
        }
        return 'black--text'
      } else return ''
    },

    user () {
      return this.$store.state.profile.user
    },

    needsAssignee () {
      return this.order.assignee === 'Claim'
    }
  },
  data () {
    return {
      fab: false,
      shippingClasses: [
        'fedex',
        'ups',
        'dhl',
        'usps'
      ],
      canAddBoxStatus: 58,
      canShipStatus: 59,

      cardStyle: {
        'border-left-color': this.order.color ?? '#A5D6A7' + ' !important'
      },

      // card details
      hasAnError: false,
      assigneeColor: null,
      shipperClass: '',
      loadingItemCount: false,
      itemCount: -1
    }
  },
  apollo: {
    itemCount: {
      query () {
        const label = this.order.label
        if (label.includes('RMA')) { // if order is an rma
          if (label.includes('S')) { // if for sale rma
            return GET_ITEM_COUNT_FOR_RMA
          } else { // if for purchase rma
            return GET_ITEM_COUNT_FOR_PURCHASE_RMA
          }
        } else { // it is for a sale
          return GET_ITEM_COUNT_FOR_SALE
        }
      },
      variables () {
        return {
          id: this.order.id
        }
      },
      update: data => {
        if (data.count.annotations) {
          return data.count?.annotations?.find(a => a.name === 'itemCount')?.value ?? 'ERROR'
        }
      },
      fetchPolicy: 'cache-and-network',
      skip () {
        return this.order.itemCount !== -1
      },
      watchLoading (isLoading) {
        this.loadingItemCount = isLoading
      }
    }
  },
  methods: {
    goToOrder () {
      if (this.order.label.includes('ST')) {
        ViewSaleTransaction({ id: this.order.id })
      } else if (this.order.label.includes('RMA')) {
        if (this.order.label.includes('S')) {
          ViewSaleRma({ id: this.order.id })
        } else {
          ViewPurchaseRma({ id: this.order.id })
        }
      }
    },

    claim () {

    },

    test () {
      this.$router.push({ name: 'salesOrders', params: { st: this.order.id } })
    }
  },
  created () {
    if (this.itemCount === -1) { // not sure when apollo cache happens
      this.itemCount = this.order.itemCount
    }
  },
  mounted () {
    this.shipperClass = this.shippingClasses.filter(item => {
      return this.order.carrier.toLowerCase().includes(item)
    })
    this.assigneeColor = this.order.status > 9 ? 'blue' : null
  }
}
</script>
