<template>
  <v-speed-dial
    v-model="fab"
    bottom
    right
    direction="left"
    absolute
    transition="slide-x-reverse"
  >
    <template #activator>
      <v-btn
        v-model="fab"
        fab
        color="primary darken-2"
        small
        dark
      >
        <v-icon>fal fa-print</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
    </template>
    <v-btn
      v-if="showPickListButton"
      dark
      fab
      small
      color="green"
      @click="$emit('list')"
    >
      <v-icon>fal fa-list</v-icon>
    </v-btn>
    <v-btn
      v-if="showPackSlipButton"
      dark
      fab
      small
      color="brown"
      @click="$emit('slip')"
    >
      <v-icon>fal fa-box</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  name: 'PrintSpeedDial',
  computed: {
    showPackSlipButton () {
      return !!this.$listeners.slip
    },

    showPickListButton () {
      return !!this.$listeners.list
    }
  },
  data () {
    return {
      fab: false
    }
  }
}
</script>
