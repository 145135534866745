<template>
    <v-container
      fluid
      grid-list-xl
    >
      <v-layout row nowrap justify-space-between fill-height align-center>
        <v-flex shrink>
          <slot name="info"></slot>
        </v-flex>
        <!-- Sorting Button -->
        <!-- <v-flex xs2>
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on }">
              <v-btn
                color="info"
                @click="updateDirection"
                v-on="on"
              >
                {{ 'Sort By: ' + sortConfig.sortBy.name }}
                <v-icon small style="padding-left: 5px;">fal {{ sortConfig.descending ? `fa-sort-numeric-down-alt` : 'fa-sort-numeric-up' }}</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile
                v-for="(item, index) in filterSorts()"
                :key="index"
                @click="updateSort(item)"
              >
                <v-list-tile-title>{{ item.name }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-flex> -->
        <!-- Search Bar -->
        <v-flex xs5>
          <v-text-field
            v-model="text"
            solo
            hide-details
            label="Search"
            @input="$emit('searched', text)"
          >
          </v-text-field>
        </v-flex>
        <!-- Refresh Button -->
        <v-flex shrink>
          <v-btn
            class="justify-end"
            @click="$emit('refresh')"
          >
            <v-icon
              v-if="!loading"
              class="far fa-sync"
              color="info"
              small
            ></v-icon>
            <v-progress-circular
              v-else
              color="info"
              indeterminate
              size="25"
              width="3"
            ></v-progress-circular>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>
export default {
  name: 'filterBar',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    selectedOrder () {
      const order = this.$store.state.orders.selectedShipment
      return order || { status: 0 }
    },

    showShip () {
      return this.$route.fullPath.includes('ship')
    },

    actionButton () {
      if (this.selectedOrder.status === 58) {
        return { disabled: false, color: 'brown', text: 'Add Box' }
      } else if (this.selectedOrder.status === 59) {
        return { disabled: false, color: 'info', text: 'Ship' }
      } else {
        return { disabled: true, color: '', text: 'Actions' }
      }
    },

    sortConfig: function () {
      return this.$store.state.orders.listSortConfig
    }
  },
  data () {
    return {
      text: '',

      sorters: [
        { name: 'ID', field: 'id' },
        { name: 'Items', field: 'partsSold' }
      ],

      saving: false
    }
  },
  methods: {
    updateSort (sorter) {
      const payload = {
        key: 'sortBy',
        value: sorter
      }
      this.$store.dispatch('orders/changeListSortConfig', payload)
    },

    updateDirection () {
      const payload = {
        key: 'descending',
        value: !this.sortConfig.descending
      }
      this.$store.dispatch('orders/changeListSortConfig', payload)
    },

    filterSorts () {
      return this.sorters.filter(item => item.field !== this.sortConfig.sortBy.field)
    }
  }
}
</script>
