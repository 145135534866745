<template>
  <v-card hover width="300" class="shipping-card the-drag" :style="cardStyle">
    <v-card-title class="pl-2">
      <v-layout row justify-space-between>
        <v-card v-ripple elevation="1" height="30px" class="pa-1 transaction-card" @click="goToOrder"><h4>{{ order.label }}</h4></v-card>
        <v-icon color="error" v-if="order.isInternational">far fa-globe</v-icon>
        <v-avatar
          class="assignee white--text"
          :color="order.assignee ? order.assigneeColor : '#cfcfcf'"
        >
          <span style="font-weight: bold;" :class="assigneeColorTextColor">{{ order.assignee || 'N/A' }}</span>
        </v-avatar>
      </v-layout>
    </v-card-title>
    <v-container pl-2 pt-0>
      <v-layout row justify-space-between align-start>
        <v-flex xs6>
          <v-layout column justify-start align-start>
            <v-flex v-if="!order.isCourier">{{ order.carrier }}</v-flex>
            <v-flex v-if="!order.isCourier">{{ order.service }}</v-flex>
            <v-flex v-if="order.isCourier" class="warning border-rounded">
              <h4 class="white--text">Courier</h4>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout row justify-end align-center fill-height>
            <v-flex xs2>
              <print-speed-dial
                @list="$emit('list', { value: order })"
                @slip="$emit('slip', { value: order })"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-card-actions>
      <v-flex>
        <v-icon v-if="order.battery" color="green">far fa-battery-full</v-icon>
      </v-flex>
      <v-spacer></v-spacer>
      <v-btn
        flat
        color="info"
        :disabled="!canManage"
        @click="$emit('manage', { value: order })"
      >Manage</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import mutateShipments from '@/api/graphql/mutationsJS/mutateShipments'
import { SHIPMENT_ORDER_STATUS } from '@/models/ExtraBackendModels'
import { ViewPurchaseRma, ViewSaleRma, ViewSaleTransaction } from '@/lib/routerHelper'
import PrintSpeedDial from '@/components/buttons/PrintSpeedDial'

export default {
  name: 'shippingCard',
  components: {
    'print-speed-dial': PrintSpeedDial
  },
  mixins: [mutateShipments],
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    canManage () {
      return Number(this.order.status) >= Number(SHIPMENT_ORDER_STATUS.TO_PROCESS)
    },

    borderColor () {
      if (this.order.color) {
        return ''
      }
      if (this.order.carrier) {
        return `${this.order.carrier.toLowerCase()}-border`
      } else {
        return ''
      }
    },

    assigneeColorTextColor () {
      const color = this.order.assigneeColor
      if (color) {
        const c = color.substring(1) // strip #
        const rgb = parseInt(c, 16) // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff // extract red
        const g = (rgb >> 8) & 0xff // extract green
        const b = (rgb >> 0) & 0xff // extract blue

        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

        if (luma < 156) {
          return 'white--text'
        }
        return 'black--text'
      } else return ''
    }
  },
  data () {
    return {
      dialog: false,
      refunding: false,

      cardStyle: {
        'border-color': this.order.color !== '#FFFFFF' && this.order.color ? this.order.color : '#616161'
      }
    }
  },
  methods: {
    goToOrder () {
      if (this.order.label.includes('ST')) {
        ViewSaleTransaction({ id: this.order.transactionId })
      } else if (this.order.label.includes('RMA')) {
        if (this.order.label.includes('S')) {
          ViewSaleRma({ id: this.order.transactionId })
        } else {
          ViewPurchaseRma({ id: this.order.transactionId })
        }
      }
    }

  }
}
</script>

<style>
  .shipping-card {
    border-left-style: solid;
    border-left-width: 7px;
    border-radius: 5px;
    margin-bottom: 12px;
    cursor: grab;
    position: relative;
  }

  .shipping-card:active {
    cursor: grabbing;
  }

  .transaction-card:hover {
    cursor: pointer;
  }

  .border-rounded {
    border-radius: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .fedex-border {
    border-color: #FF6200;
  }

  .ups-border {
    border-color: #330000;
  }

  .usps-border {
    border-color: #333366;
  }

  dhl-border {
    border-color: #FFCC00;
  }
</style>
